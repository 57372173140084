import React from 'react';
import { colorTokens } from '@/components/ui/colorTokens';

export const ChubbsKiss = () => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.375 41.25C9.875 37.25 10.625 31.875 14.375 30.625H30.625C36.25 31.875 36.0417 39.1667 36.25 41.25H9.375Z"
      fill={colorTokens['chubbs-body']}
      stroke={colorTokens['chubbs-outline']}
      strokeWidth="0.3125"
    />
    <path
      d="M14.5093 25.7459C14.5093 21.0313 14.5093 21.1082 14.5093 16.4155C14.5422 6.37154 30.3271 6.01479 30.2942 16.4374V26.5692"
      stroke={colorTokens['chubbs-outline']}
      strokeWidth="0.312844"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.5103 16.4155C14.5103 21.1082 14.5103 21.0314 14.5103 25.746C14.5147 27.1251 14.4378 28.5033 14.2798 29.8733H30.619C30.3994 28.7895 30.2909 27.6861 30.2952 26.5802V16.4485C30.3281 6.01485 14.5432 6.3716 14.5103 16.4155Z"
      fill={colorTokens['chubbs-body']}
    />
    <path
      d="M14.5103 16.4155C14.5103 21.1082 14.5103 21.0314 14.5103 25.746C14.5147 27.1251 14.4378 28.5033 14.2798 29.8733H30.619C30.3994 28.7895 30.2909 27.6861 30.2952 26.5802V16.4485C30.3281 6.01485 14.5432 6.3716 14.5103 16.4155Z"
      stroke={colorTokens['chubbs-outline']}
      strokeWidth="0.312844"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30.2784 15.5816C29.4935 6.32249 15.4046 6.62436 14.5703 15.5816H30.2784Z"
      fill={colorTokens['chubbs-body']}
    />
    <path
      d="M30.2784 15.5816C29.4935 6.32249 15.4046 6.62436 14.5703 15.5816H30.2784Z"
      stroke={colorTokens['chubbs-outline']}
      strokeWidth="0.312844"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.2296 21.8825C15.4099 21.8824 15.5862 21.8292 15.7365 21.7294C15.8867 21.6296 16.0041 21.4877 16.0741 21.3215C16.1441 21.1553 16.1635 20.9722 16.1298 20.795C16.0962 20.6178 16.0111 20.4545 15.8851 20.3255C15.7591 20.1965 15.5978 20.1075 15.4215 20.0697C15.2452 20.0319 15.0616 20.0469 14.8938 20.1129C14.726 20.179 14.5813 20.293 14.478 20.4408C14.3747 20.5887 14.3173 20.7637 14.313 20.9439C14.3101 21.0661 14.3316 21.1877 14.3764 21.3014C14.4211 21.4152 14.4882 21.5188 14.5736 21.6063C14.659 21.6938 14.761 21.7632 14.8737 21.8107C14.9863 21.8581 15.1073 21.8825 15.2296 21.8825V21.8825Z"
      fill={colorTokens['chubbs-eyes']}
    />
    <path
      d="M15.2296 21.8825C15.4099 21.8824 15.5862 21.8292 15.7365 21.7294C15.8867 21.6296 16.0041 21.4877 16.0741 21.3215C16.1441 21.1553 16.1635 20.9722 16.1298 20.795C16.0962 20.6178 16.0111 20.4545 15.8851 20.3255C15.7591 20.1965 15.5978 20.1075 15.4215 20.0697C15.2452 20.0319 15.0616 20.0469 14.8938 20.1129C14.726 20.179 14.5813 20.293 14.478 20.4408C14.3747 20.5887 14.3173 20.7637 14.313 20.9439C14.3101 21.0661 14.3316 21.1877 14.3764 21.3014C14.4211 21.4152 14.4882 21.5188 14.5736 21.6063C14.659 21.6938 14.761 21.7632 14.8737 21.8107C14.9863 21.8581 15.1073 21.8825 15.2296 21.8825V21.8825Z"
      stroke={colorTokens['chubbs-outline']}
      strokeWidth="0.312844"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.9234 20.0493C22.7412 20.0494 22.5631 20.1037 22.412 20.2054C22.2608 20.3072 22.1434 20.4516 22.0747 20.6204C22.006 20.7892 21.9892 20.9746 22.0264 21.1529C22.0636 21.3313 22.1531 21.4946 22.2834 21.6218C22.4138 21.7491 22.5792 21.8346 22.7584 21.8675C22.9376 21.9003 23.1226 21.879 23.2896 21.8062C23.4567 21.7335 23.5983 21.6126 23.6963 21.459C23.7943 21.3054 23.8443 21.1261 23.84 20.9439C23.8342 20.7047 23.7352 20.4771 23.5639 20.31C23.3926 20.1428 23.1627 20.0492 22.9234 20.0493Z"
      fill={colorTokens['chubbs-eyes']}
    />
    <path
      d="M22.9234 20.0493C22.7412 20.0494 22.5631 20.1037 22.412 20.2054C22.2608 20.3072 22.1434 20.4516 22.0747 20.6204C22.006 20.7892 21.9892 20.9746 22.0264 21.1529C22.0636 21.3313 22.1531 21.4946 22.2834 21.6218C22.4138 21.7491 22.5792 21.8346 22.7584 21.8675C22.9376 21.9003 23.1226 21.879 23.2896 21.8062C23.4567 21.7335 23.5983 21.6126 23.6963 21.459C23.7943 21.3054 23.8443 21.1261 23.84 20.9439C23.8342 20.7047 23.7352 20.4771 23.5639 20.31C23.3926 20.1428 23.1627 20.0492 22.9234 20.0493Z"
      stroke={colorTokens['chubbs-outline']}
      strokeWidth="0.312844"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.5749 17.4094C15.2133 17.3554 14.8438 17.4128 14.5156 17.574"
      stroke={colorTokens['chubbs-outline']}
      strokeWidth="0.312844"
      strokeMiterlimit="22.93"
      strokeLinecap="round"
    />
    <path
      d="M22.5403 17.4093C22.9019 17.3564 23.2711 17.4138 23.5996 17.5739"
      stroke={colorTokens['chubbs-outline']}
      strokeWidth="0.312844"
      strokeMiterlimit="22.93"
      strokeLinecap="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30.1629 28.4297H14.5097C14.2186 28.4297 13.9394 28.5453 13.7336 28.7512C13.5277 28.9571 13.412 29.2363 13.412 29.5274C13.412 29.8185 13.5277 30.0977 13.7336 30.3036C13.9394 30.5094 14.2186 30.6251 14.5097 30.6251H30.152C30.4431 30.6251 30.7223 30.5094 30.9282 30.3036C31.134 30.0977 31.2497 29.8185 31.2497 29.5274C31.2497 29.2363 31.134 28.9571 30.9282 28.7512C30.7223 28.5453 30.4431 28.4297 30.152 28.4297H30.1629Z"
      fill={colorTokens['chubbs-body']}
    />
    <path
      d="M30.1629 28.4297H14.5097C14.2186 28.4297 13.9394 28.5453 13.7336 28.7512C13.5277 28.9571 13.412 29.2363 13.412 29.5274C13.412 29.8185 13.5277 30.0977 13.7336 30.3036C13.9394 30.5094 14.2186 30.6251 14.5097 30.6251H30.152C30.4431 30.6251 30.7223 30.5094 30.9282 30.3036C31.134 30.0977 31.2497 29.8185 31.2497 29.5274C31.2497 29.2363 31.134 28.9571 30.9282 28.7512C30.7223 28.5453 30.4431 28.4297 30.152 28.4297H30.1629Z"
      stroke={colorTokens['chubbs-outline']}
      strokeWidth="0.312844"
      strokeMiterlimit="22.93"
      strokeLinecap="round"
    />
    <path
      d="M30.3116 15.5049H6.25"
      stroke={colorTokens['chubbs-outline']}
      strokeWidth="0.312844"
      strokeMiterlimit="22.93"
      strokeLinecap="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.3075 30.5977L13.1249 31.2501C16.1792 30.7566 20.2011 30.7622 23.2922 30.6306L14.3075 30.5977Z"
      fill={colorTokens['chubbs-outline']}
    />
    <path
      d="M14.3079 30.5977L23.2925 30.6306"
      stroke={colorTokens['chubbs-outline']}
      strokeWidth="0.312844"
      strokeMiterlimit="22.93"
      strokeLinecap="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.7727 15.4551L14.5093 16.4375C17.56 15.9444 20.637 15.631 23.7245 15.499L14.7727 15.4551Z"
      fill={colorTokens['chubbs-outline']}
    />
    <path
      d="M14.7727 15.4551L14.5093 16.4375C17.56 15.9444 20.637 15.631 23.7245 15.499L14.7727 15.4551Z"
      stroke={colorTokens['chubbs-outline']}
      strokeWidth="0.312844"
      strokeMiterlimit="22.93"
      strokeLinecap="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.8175 21.0537C14.7903 21.055 14.7645 21.0665 14.7453 21.0857C14.726 21.105 14.7146 21.1308 14.7133 21.158C14.7133 21.1857 14.7242 21.2122 14.7438 21.2317C14.7634 21.2513 14.7899 21.2623 14.8175 21.2623C14.8314 21.2631 14.8454 21.2609 14.8584 21.256C14.8714 21.251 14.8832 21.2433 14.893 21.2335C14.9029 21.2237 14.9105 21.2118 14.9155 21.1988C14.9205 21.1858 14.9226 21.1719 14.9218 21.158C14.9218 21.1303 14.9108 21.1038 14.8913 21.0843C14.8717 21.0647 14.8452 21.0537 14.8175 21.0537V21.0537Z"
      fill={colorTokens['chubbs-outline']}
    />
    <path
      d="M14.8175 21.0537C14.7903 21.055 14.7645 21.0665 14.7453 21.0857C14.726 21.105 14.7146 21.1308 14.7133 21.158C14.7133 21.1857 14.7242 21.2122 14.7438 21.2317C14.7634 21.2513 14.7899 21.2623 14.8175 21.2623C14.8314 21.2631 14.8454 21.2609 14.8584 21.256C14.8714 21.251 14.8832 21.2433 14.893 21.2335C14.9029 21.2237 14.9105 21.2118 14.9155 21.1988C14.9205 21.1858 14.9226 21.1719 14.9218 21.158C14.9218 21.1303 14.9108 21.1038 14.8913 21.0843C14.8717 21.0647 14.8452 21.0537 14.8175 21.0537V21.0537Z"
      stroke={colorTokens['chubbs-outline']}
      strokeWidth="0.312844"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.4137 21.273C22.4413 21.273 22.4678 21.262 22.4874 21.2425C22.507 21.2229 22.5179 21.1964 22.5179 21.1687C22.5179 21.1411 22.507 21.1146 22.4874 21.095C22.4678 21.0754 22.4413 21.0645 22.4137 21.0645C22.386 21.0645 22.3595 21.0754 22.3399 21.095C22.3204 21.1146 22.3094 21.1411 22.3094 21.1687C22.3094 21.1964 22.3204 21.2229 22.3399 21.2425C22.3595 21.262 22.386 21.273 22.4137 21.273Z"
      fill={colorTokens['chubbs-outline']}
    />
    <path
      d="M22.4137 21.273C22.4413 21.273 22.4678 21.262 22.4874 21.2425C22.507 21.2229 22.5179 21.1964 22.5179 21.1687C22.5179 21.1411 22.507 21.1146 22.4874 21.095C22.4678 21.0754 22.4413 21.0645 22.4137 21.0645C22.386 21.0645 22.3595 21.0754 22.3399 21.095C22.3204 21.1146 22.3094 21.1411 22.3094 21.1687C22.3094 21.1964 22.3204 21.2229 22.3399 21.2425C22.3595 21.262 22.386 21.273 22.4137 21.273Z"
      stroke={colorTokens['chubbs-outline']}
      strokeWidth="0.312844"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.5387 22.8037C18.0942 22.9135 17.6716 23.5007 18.3302 23.7422C19.362 24.1319 17.4685 24.5381 18.3302 25.1912C18.4839 25.3065 18.7034 25.2241 18.879 25.1912"
      stroke={colorTokens['chubbs-outline']}
      strokeWidth="0.312844"
      strokeMiterlimit="22.93"
      strokeLinecap="round"
    />
    <path
      d="M15.2951 18.4571C15.7812 18.4528 16.2577 18.593 16.664 18.8599C17.0703 19.1268 17.3882 19.5084 17.5773 19.9563C17.7663 20.4042 17.818 20.8982 17.7259 21.3755C17.6337 21.8529 17.4018 22.2921 17.0596 22.6374C16.7174 22.9827 16.2803 23.2185 15.8038 23.315C15.3273 23.4114 14.8329 23.3641 14.3833 23.1791C13.9337 22.9941 13.5492 22.6797 13.2787 22.2758C13.0081 21.8718 12.8637 21.3966 12.8636 20.9105C12.8636 20.2636 13.1191 19.6429 13.5744 19.1834C14.0298 18.7239 14.6482 18.4629 15.2951 18.4571V18.4571Z"
      stroke={colorTokens['chubbs-outline']}
      strokeWidth="0.312844"
      strokeMiterlimit="22.93"
      strokeLinecap="round"
    />
    <path
      d="M22.8147 18.457C23.2999 18.457 23.7742 18.6009 24.1777 18.8705C24.5812 19.1401 24.8956 19.5232 25.0813 19.9715C25.267 20.4198 25.3156 20.9131 25.2209 21.389C25.1262 21.8649 24.8926 22.3021 24.5495 22.6452C24.2064 22.9883 23.7692 23.2219 23.2933 23.3166C22.8174 23.4113 22.3241 23.3627 21.8758 23.177C21.4275 22.9913 21.0444 22.6769 20.7748 22.2734C20.5052 21.8699 20.3613 21.3956 20.3613 20.9104C20.3613 20.2597 20.6198 19.6357 21.0799 19.1756C21.54 18.7155 22.164 18.457 22.8147 18.457V18.457Z"
      stroke={colorTokens['chubbs-outline']}
      strokeWidth="0.312844"
      strokeMiterlimit="22.93"
      strokeLinecap="round"
    />
    <path
      d="M25.3943 20.5811H30.3065"
      stroke={colorTokens['chubbs-outline']}
      strokeWidth="0.312844"
      strokeMiterlimit="22.93"
      strokeLinecap="round"
    />
    <path
      d="M17.4901 19.8465C18.039 18.8476 19.9709 18.7488 20.7503 19.7532"
      stroke={colorTokens['chubbs-outline']}
      strokeWidth="0.312844"
      strokeMiterlimit="22.93"
      strokeLinecap="round"
    />
  </svg>
);
