import React, { FC, useMemo } from 'react';
import { IconGhost } from '@/components/ui/icons/IconGhost';
import { colorsDeprecated } from '@/components/ui/colors';
import { UserAvatarFallbackHarnessSelectors } from '@/components/ui/UserAvatar/components/UserAvatarFallback/UserAvatarFallback.harness';
import styles from './UserAvatarFallback.module.scss';
import { avatars, backgrounds } from '../avatars';

interface Props {
  userId: number;
  fullName: string;
  isInactive: boolean;
}

export const UserAvatarFallback: FC<Props> = (props) => {
  const { userId, fullName, isInactive } = props;

  const fullNameHash = useMemo(() => Array.from(fullName).reduce(
    (acc, char) => acc + char.charCodeAt(0),
    0,
  ), [fullName]);

  const avatarIndex = (userId + fullNameHash) % avatars.length;

  const backgroundIndex = Math.abs((userId - fullNameHash))
    % backgrounds.length;

  const AvatarComponent = avatars[avatarIndex];
  const background = backgrounds[backgroundIndex];

  if (isInactive || !AvatarComponent || !background) {
    return (
      <div
        className={styles.container}
        style={{ '--background': '#F5F6FA' } as React.CSSProperties}
      >
        <IconGhost
          color={colorsDeprecated.gray30}
          viewBox='-4 -4 32 32'
        />
      </div>
    );
  }

  return (
    <div
      className={styles.container}
      style={{ '--background': background } as React.CSSProperties}
      data-qa={UserAvatarFallbackHarnessSelectors.DataQa}
    >
      <AvatarComponent />
    </div>
  );
};
