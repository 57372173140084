import { colorTokens } from '@/components/ui/colorTokens';
import { ChubbsCalmWithHat } from './ChubbsCalmWithHat';
import { ChubbsCalm } from './ChubbsCalm';
import { ChubbsCoolSmile } from './ChubbsCoolSmile';
import { ChubbsCool } from './ChubbsCool';
import { ChubbsDefault } from './ChubbsDefault';
import { ChubbsExcited } from './ChubbsExcited';
import { ChubbsHappy } from './ChubbsHappy';
import { ChubbsKiss } from './ChubbsKiss';
import { ChubbsNerd } from './ChubbsNerd';
import { ChubbsSmile } from './ChubbsSmile';

export const avatars = [
  ChubbsCalmWithHat,
  ChubbsCalm,
  ChubbsCoolSmile,
  ChubbsCool,
  ChubbsDefault,
  ChubbsExcited,
  ChubbsHappy,
  ChubbsKiss,
  ChubbsNerd,
  ChubbsSmile,
];

export const backgrounds = [
  colorTokens['bg-brand-secondary'],
  colorTokens['bg-danger-secondary'],
  colorTokens['bg-warning-secondary'],
  colorTokens['bg-accent-lime-secondary'],
  colorTokens['bg-accent-purple'],
  colorTokens['bg-success-secondary'],
  colorTokens['bg-accent-magenta-secondary'],
  colorTokens['bg-accent-purple-secondary'],
  colorTokens['bg-info-secondary'],
  colorTokens['bg-info'],
];
