import React from 'react';
import { colorTokens } from '@/components/ui/colorTokens';

export const ChubbsCalm = () => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5 40.625C5.5 36.625 8.125 31.875 10.625 30H26.25C31.875 31.25 31.6667 38.5417 31.875 40.625H5Z"
      fill={colorTokens['chubbs-body']}
      stroke={colorTokens['chubbs-outline']}
      strokeWidth="0.3125"
    />
    <path
      d="M26.7276 25.7321V16.4057C26.7111 6.37156 10.933 6.01496 10.9495 16.4386V26.566C10.9513 28.0639 10.78 29.4144 10.4931 30.6945H27.1964C26.8926 29.2714 26.7139 27.6588 26.7276 25.7321Z"
      fill={colorTokens['chubbs-body']}
    />
    <path
      d="M26.2601 18.7501C25.774 18.7457 25.2975 18.8859 24.8912 19.1528C24.4848 19.4198 24.167 19.8014 23.9779 20.2493C23.7888 20.6972 23.7371 21.1911 23.8293 21.6685C23.9214 22.1458 24.1534 22.585 24.4956 22.9303C24.8378 23.2756 25.2749 23.5115 25.7514 23.6079C26.2279 23.7044 26.7223 23.6571 27.1719 23.4721C27.6214 23.2871 28.0059 22.9726 28.2765 22.5687C28.547 22.1648 28.6915 21.6896 28.6915 21.2035C28.6915 20.5566 28.4361 19.9359 27.9807 19.4764C27.5254 19.0169 26.907 18.7559 26.2601 18.7501V18.7501Z"
      stroke={colorTokens['chubbs-outline']}
      strokeWidth="0.312844"
      strokeMiterlimit="22.93"
      strokeLinecap="round"
    />
    <path
      d="M18.7406 18.75C18.2554 18.75 17.781 18.8939 17.3776 19.1635C16.9741 19.433 16.6597 19.8162 16.474 20.2645C16.2883 20.7128 16.2397 21.2061 16.3344 21.682C16.429 22.1579 16.6627 22.595 17.0058 22.9381C17.3489 23.2813 17.7861 23.5149 18.262 23.6096C18.7379 23.7042 19.2312 23.6557 19.6794 23.47C20.1277 23.2843 20.5109 22.9698 20.7805 22.5664C21.0501 22.1629 21.1939 21.6886 21.1939 21.2034C21.1939 20.5527 20.9355 19.9287 20.4754 19.4686C20.0153 19.0085 19.3913 18.75 18.7406 18.75V18.75Z"
      stroke={colorTokens['chubbs-outline']}
      strokeWidth="0.312844"
      strokeMiterlimit="22.93"
      strokeLinecap="round"
    />
    <path
      d="M16.1622 20.874H11.25"
      stroke={colorTokens['chubbs-outline']}
      strokeWidth="0.312844"
      strokeMiterlimit="22.93"
      strokeLinecap="round"
    />
    <path
      d="M24.065 20.1385C23.5162 19.1396 21.5842 19.0408 20.8049 20.0452"
      stroke={colorTokens['chubbs-outline']}
      strokeWidth="0.312844"
      strokeMiterlimit="22.93"
      strokeLinecap="round"
    />
    <path
      d="M10.9486 28.5001V26.566V16.4386C10.9321 6.01496 26.7102 6.37156 26.7267 16.4057C26.7267 21.0963 26.7267 21.0195 26.7267 25.7321L26.9203 28.5001"
      stroke={colorTokens['chubbs-outline']}
      strokeWidth="0.312709"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.0972 28.4141H26.7327C27.0237 28.4141 27.3028 28.5297 27.5085 28.7354C27.7143 28.9412 27.8299 29.2203 27.8299 29.5113C27.8299 29.8023 27.7143 30.0814 27.5085 30.2871C27.3028 30.4929 27.0237 30.6085 26.7327 30.6085H11.0972C10.8062 30.6085 10.5271 30.4929 10.3214 30.2871C10.1156 30.0814 10 29.8023 10 29.5113C10 29.2203 10.1156 28.9412 10.3214 28.7354C10.5271 28.5297 10.8062 28.4141 11.0972 28.4141Z"
      fill={colorTokens['chubbs-body']}
    />
    <path
      d="M11.0972 28.4141H26.7327C27.0237 28.4141 27.3028 28.5297 27.5085 28.7354C27.7143 28.9412 27.8299 29.2203 27.8299 29.5113C27.8299 29.8023 27.7143 30.0814 27.5085 30.2871C27.3028 30.4929 27.0237 30.6085 26.7327 30.6085H11.0972C10.8062 30.6085 10.5271 30.4929 10.3214 30.2871C10.1156 30.0814 10 29.8023 10 29.5113C10 29.2203 10.1156 28.9412 10.3214 28.7354C10.5271 28.5297 10.8062 28.4141 11.0972 28.4141Z"
      stroke={colorTokens['chubbs-outline']}
      strokeWidth="0.312709"
      strokeMiterlimit="22.93"
      strokeLinecap="round"
    />
    <path
      d="M10.9486 15.4941H34.9998"
      stroke={colorTokens['chubbs-outline']}
      strokeWidth="0.312709"
      strokeMiterlimit="22.93"
      strokeLinecap="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.0777 30.6198L27.6023 30.3862L28.125 30.6249L28.75 31.2497C25.7007 30.7573 21.194 30.787 18.1079 30.6582L27.0777 30.6198Z"
      fill={colorTokens['chubbs-outline']}
    />
    <path
      d="M27.0777 30.6201L18.1079 30.6585"
      stroke={colorTokens['chubbs-outline']}
      strokeWidth="0.312709"
      strokeMiterlimit="22.93"
      strokeLinecap="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.4798 15.4561L26.7212 16.4271C23.672 15.9343 20.5961 15.6228 17.51 15.4945L26.4798 15.4561Z"
      fill={colorTokens['chubbs-outline']}
    />
    <path
      d="M26.4798 15.4561L26.7212 16.4271C23.672 15.9343 20.5961 15.6228 17.51 15.4945L26.4798 15.4561Z"
      stroke={colorTokens['chubbs-outline']}
      strokeWidth="0.312709"
      strokeMiterlimit="22.93"
      strokeLinecap="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.2003 22.3792C26.0195 22.3792 25.8428 22.3258 25.6924 22.2256C25.542 22.1254 25.4246 21.9829 25.3549 21.8162C25.2853 21.6494 25.2665 21.4657 25.3009 21.2883C25.3354 21.1109 25.4216 20.9476 25.5486 20.8191C25.6757 20.6905 25.8379 20.6024 26.0149 20.5658C26.1919 20.5293 26.3757 20.5458 26.5433 20.6135C26.7109 20.6812 26.8547 20.7969 26.9567 20.9461C27.0587 21.0953 27.1143 21.2714 27.1164 21.4521C27.1172 21.5731 27.094 21.6931 27.0484 21.8052C27.0027 21.9173 26.9354 22.0193 26.8504 22.1054C26.7653 22.1915 26.6641 22.26 26.5525 22.307C26.441 22.354 26.3213 22.3785 26.2003 22.3792Z"
      fill={colorTokens['chubbs-eyes']}
    />
    <path
      d="M26.2003 22.3792C26.0195 22.3792 25.8428 22.3258 25.6924 22.2256C25.542 22.1254 25.4246 21.9829 25.3549 21.8162C25.2853 21.6494 25.2665 21.4657 25.3009 21.2883C25.3354 21.1109 25.4216 20.9476 25.5486 20.8191C25.6757 20.6905 25.8379 20.6024 26.0149 20.5658C26.1919 20.5293 26.3757 20.5458 26.5433 20.6135C26.7109 20.6812 26.8547 20.7969 26.9567 20.9461C27.0587 21.0953 27.1143 21.2714 27.1164 21.4521C27.1172 21.5731 27.094 21.6931 27.0484 21.8052C27.0027 21.9173 26.9354 22.0193 26.8504 22.1054C26.7653 22.1915 26.6641 22.26 26.5525 22.307C26.441 22.354 26.3213 22.3785 26.2003 22.3792Z"
      stroke={colorTokens['chubbs-outline']}
      strokeWidth="0.312709"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.5041 20.5469C18.6858 20.5469 18.8634 20.6009 19.0143 20.7021C19.1652 20.8032 19.2826 20.947 19.3516 21.115C19.4206 21.2831 19.4381 21.4679 19.4018 21.6459C19.3656 21.8239 19.2772 21.9871 19.148 22.1148C19.0188 22.2425 18.8545 22.3289 18.6761 22.363C18.4976 22.3971 18.3131 22.3773 18.1459 22.3063C17.9787 22.2353 17.8363 22.1161 17.737 21.964C17.6377 21.8119 17.5858 21.6337 17.588 21.4521C17.5908 21.211 17.6886 20.9808 17.8601 20.8113C18.0317 20.6419 18.263 20.5469 18.5041 20.5469V20.5469Z"
      fill={colorTokens['chubbs-eyes']}
    />
    <path
      d="M18.5041 20.5469C18.6858 20.5469 18.8634 20.6009 19.0143 20.7021C19.1652 20.8032 19.2826 20.947 19.3516 21.115C19.4206 21.2831 19.4381 21.4679 19.4018 21.6459C19.3656 21.8239 19.2772 21.9871 19.148 22.1148C19.0188 22.2425 18.8545 22.3289 18.6761 22.363C18.4976 22.3971 18.3131 22.3773 18.1459 22.3063C17.9787 22.2353 17.8363 22.1161 17.737 21.964C17.6377 21.8119 17.5858 21.6337 17.588 21.4521C17.5908 21.211 17.6886 20.9808 17.8601 20.8113C18.0317 20.6419 18.263 20.5469 18.5041 20.5469V20.5469Z"
      stroke={colorTokens['chubbs-outline']}
      strokeWidth="0.312709"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M25.8547 17.9088C26.2161 17.8559 26.5852 17.9132 26.9135 18.0734"
      stroke={colorTokens['chubbs-outline']}
      strokeWidth="0.312709"
      strokeMiterlimit="22.93"
      strokeLinecap="round"
    />
    <path
      d="M18.8921 17.9084C18.5306 17.8544 18.1613 17.9118 17.8333 18.073"
      stroke={colorTokens['chubbs-outline']}
      strokeWidth="0.312709"
      strokeMiterlimit="22.93"
      strokeLinecap="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.474 21.842C26.5017 21.842 26.5282 21.8529 26.5477 21.8725C26.5673 21.892 26.5783 21.9186 26.5783 21.9462C26.5769 21.9734 26.5655 21.9992 26.5463 22.0184C26.527 22.0377 26.5012 22.0491 26.474 22.0504C26.4464 22.0504 26.4199 22.0394 26.4003 22.0199C26.3808 22.0004 26.3698 21.9738 26.3698 21.9462C26.369 21.9323 26.3711 21.9184 26.3761 21.9054C26.3811 21.8924 26.3887 21.8806 26.3986 21.8707C26.4084 21.8609 26.4202 21.8532 26.4332 21.8483C26.4462 21.8433 26.4601 21.8412 26.474 21.842Z"
      fill={colorTokens['chubbs-outline']}
    />
    <path
      d="M26.474 21.842C26.5017 21.842 26.5282 21.8529 26.5477 21.8725C26.5673 21.892 26.5783 21.9186 26.5783 21.9462C26.5769 21.9734 26.5655 21.9992 26.5463 22.0184C26.527 22.0377 26.5012 22.0491 26.474 22.0504C26.4464 22.0504 26.4199 22.0394 26.4003 22.0199C26.3808 22.0004 26.3698 21.9738 26.3698 21.9462C26.369 21.9323 26.3711 21.9184 26.3761 21.9054C26.3811 21.8924 26.3887 21.8806 26.3986 21.8707C26.4084 21.8609 26.4202 21.8532 26.4332 21.8483C26.4462 21.8433 26.4601 21.8412 26.474 21.842Z"
      stroke={colorTokens['chubbs-outline']}
      strokeWidth="0.312709"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.8371 21.9464C18.8099 21.9451 18.7842 21.9336 18.7649 21.9144C18.7457 21.8951 18.7342 21.8694 18.7329 21.8421C18.7329 21.814 18.7437 21.7869 18.7631 21.7664C18.7825 21.746 18.809 21.7338 18.8371 21.7324C18.8513 21.7324 18.8653 21.7353 18.8783 21.7408C18.8914 21.7464 18.9031 21.7546 18.9129 21.7649C18.9226 21.7751 18.9302 21.7873 18.9351 21.8006C18.94 21.8139 18.9421 21.828 18.9414 21.8421C18.9414 21.8698 18.9304 21.8963 18.9109 21.9159C18.8913 21.9354 18.8648 21.9464 18.8371 21.9464V21.9464Z"
      fill={colorTokens['chubbs-outline']}
    />
    <path
      d="M18.8371 21.9464C18.8099 21.9451 18.7842 21.9336 18.7649 21.9144C18.7457 21.8951 18.7342 21.8694 18.7329 21.8421C18.7329 21.814 18.7437 21.7869 18.7631 21.7664C18.7825 21.746 18.809 21.7338 18.8371 21.7324C18.8513 21.7324 18.8653 21.7353 18.8783 21.7408C18.8914 21.7464 18.9031 21.7546 18.9129 21.7649C18.9226 21.7751 18.9302 21.7873 18.9351 21.8006C18.94 21.8139 18.9421 21.828 18.9414 21.8421C18.9414 21.8698 18.9304 21.8963 18.9109 21.9159C18.8913 21.9354 18.8648 21.9464 18.8371 21.9464V21.9464Z"
      stroke={colorTokens['chubbs-outline']}
      strokeWidth="0.312709"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.9524 24.1299C20.1664 24.6456 20.7643 24.7114 21.2581 24.7114"
      stroke={colorTokens['chubbs-outline']}
      strokeWidth="0.312709"
      strokeMiterlimit="22.93"
      strokeLinecap="round"
    />
  </svg>
);
