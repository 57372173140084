export abstract class ComponentHarness {
  abstract dataQa: string;

  getElement() {
    return cy.get(this.makeSelector(this.dataQa));
  }

  getElementByDataQa(dataQa: string) {
    return cy.get(this.makeSelector(dataQa));
  }

  makeSelector(dataQA: string): string {
    return `[data-qa="${dataQA}"]`;
  }

  getNestedElement(dataQa: string) {
    return this.getElement().find(this.makeSelector(dataQa));
  }

  getNestedByAlt(alt: string) {
    return this.getElement().find(`[alt="${alt}"]`);
  }

  getNestedElementById(id: string) {
    return this.getElement().find(`[id="${id}"]`);
  }

  getNestedByText(text: string) {
    return this.getElement().contains(text);
  }

  click() {
    return this.getElement().click();
  }

  assertExists() {
    this.getElement().should('exist');
  }

  assertNotExist() {
    this.getElement().should('not.exist');
  }
}
