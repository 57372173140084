import { createContext, useContext } from 'react';

interface FormFieldContext {
  inputIdMod?: string;
}

const formFieldContext = createContext<FormFieldContext>({});

export const FormFieldContextProvider = formFieldContext.Provider;
export const useFormFieldContext = () => useContext(formFieldContext);
