import React from 'react';
import { colorTokens } from '@/components/ui/colorTokens';

export const ChubbsExcited = () => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.375 41.25C9.875 37.25 10.625 31.875 14.375 30.625H30.625C36.25 31.875 36.0417 39.1667 36.25 41.25H9.375Z"
      fill={colorTokens['chubbs-body']}
      stroke={colorTokens['chubbs-outline']}
      strokeWidth="0.3125"
    />
    <path
      d="M14.5107 25.7456V16.4153C14.5436 6.37159 30.3282 6.01485 30.2952 16.4373V26.5688C30.2929 28.057 30.4844 29.4138 30.8022 30.7034H14.1711C14.3854 29.224 14.5107 27.5934 14.5107 25.7456Z"
      fill={colorTokens['chubbs-body']}
    />
    <path
      d="M14.5105 25.7456C14.5105 21.0311 14.5105 21.1079 14.5105 16.4153C14.5434 6.37159 30.328 6.01485 30.2951 16.4373V26.5688"
      stroke={colorTokens['chubbs-outline']}
      strokeWidth="0.312838"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.5103 16.4169C14.5103 21.1094 14.5103 21.0326 14.5103 25.7471C14.5147 27.1262 14.4378 28.5043 14.2798 29.8744H30.6187C30.399 28.7906 30.2905 27.6872 30.2949 26.5813V16.4498C30.3278 6.01637 14.5432 6.37312 14.5103 16.4169Z"
      fill={colorTokens['chubbs-body']}
    />
    <path
      d="M14.5103 16.4169C14.5103 21.1094 14.5103 21.0326 14.5103 25.7471C14.5147 27.1262 14.4378 28.5043 14.2798 29.8744H30.6187C30.399 28.7906 30.2905 27.6872 30.2949 26.5813V16.4498C30.3278 6.01637 14.5432 6.37312 14.5103 16.4169Z"
      stroke={colorTokens['chubbs-outline']}
      strokeWidth="0.312838"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30.2793 15.5814C29.4944 6.32255 15.4058 6.62441 14.5715 15.5814H30.2793Z"
      fill={colorTokens['chubbs-body']}
    />
    <path
      d="M30.2793 15.5814C29.4944 6.32255 15.4058 6.62441 14.5715 15.5814H30.2793Z"
      stroke={colorTokens['chubbs-outline']}
      strokeWidth="0.312838"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.2283 21.8824C15.4087 21.8824 15.585 21.8291 15.7352 21.7294C15.8854 21.6296 16.0029 21.4877 16.0728 21.3215C16.1428 21.1553 16.1622 20.9722 16.1286 20.795C16.0949 20.6178 16.0098 20.4545 15.8838 20.3255C15.7578 20.1965 15.5966 20.1075 15.4202 20.0697C15.2439 20.0319 15.0604 20.0469 14.8925 20.1129C14.7247 20.179 14.5801 20.293 14.4768 20.4408C14.3735 20.5886 14.3161 20.7636 14.3118 20.9439C14.3089 21.0661 14.3304 21.1877 14.3752 21.3014C14.4199 21.4152 14.487 21.5188 14.5724 21.6063C14.6578 21.6937 14.7598 21.7632 14.8724 21.8106C14.9851 21.8581 15.1061 21.8825 15.2283 21.8824Z"
      fill={colorTokens['chubbs-eyes']}
    />
    <path
      d="M14.5281 21.5554C14.644 21.6935 14.7982 21.7944 14.9712 21.8453C15.1441 21.8963 15.3283 21.895 15.5006 21.8418C15.6729 21.7886 15.8257 21.6857 15.9398 21.546C16.0539 21.4064 16.1243 21.2362 16.1421 21.0568C16.1599 20.8773 16.1245 20.6966 16.0401 20.5372C15.9557 20.3778 15.8262 20.2469 15.6677 20.1608C15.5093 20.0747 15.329 20.0372 15.1493 20.0531C14.9697 20.069 14.7988 20.1375 14.6579 20.25C14.5624 20.3264 14.4831 20.421 14.4247 20.5284C14.3664 20.6358 14.3301 20.7538 14.318 20.8754C14.3059 20.997 14.3182 21.1199 14.3543 21.2366C14.3904 21.3534 14.4495 21.4618 14.5281 21.5554V21.5554Z"
      stroke={colorTokens['chubbs-outline']}
      strokeWidth="0.312838"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.9244 20.0493C22.7422 20.0494 22.5641 20.1037 22.4129 20.2054C22.2618 20.3072 22.1443 20.4516 22.0757 20.6204C22.007 20.7891 21.9902 20.9745 22.0273 21.1529C22.0645 21.3313 22.154 21.4945 22.2844 21.6218C22.4148 21.7491 22.5802 21.8346 22.7594 21.8674C22.9386 21.9003 23.1235 21.8789 23.2906 21.8062C23.4576 21.7334 23.5992 21.6126 23.6972 21.459C23.7953 21.3054 23.8453 21.1261 23.8409 20.9439C23.8352 20.7047 23.7361 20.4771 23.5648 20.31C23.3936 20.1428 23.1637 20.0492 22.9244 20.0493Z"
      fill={colorTokens['chubbs-eyes']}
    />
    <path
      d="M23.5602 20.3057C23.429 20.1793 23.2631 20.0949 23.0836 20.0633C22.9042 20.0316 22.7194 20.0542 22.5529 20.1281C22.3863 20.2019 22.2455 20.3238 22.1486 20.478C22.0516 20.6323 22.0028 20.8119 22.0084 20.9941C22.014 21.1762 22.0737 21.3525 22.18 21.5005C22.2862 21.6485 22.4342 21.7615 22.605 21.825C22.7758 21.8885 22.9616 21.8997 23.1387 21.8571C23.3159 21.8145 23.4763 21.7201 23.5996 21.5859C23.7615 21.4096 23.848 21.177 23.8406 20.9378C23.8333 20.6985 23.7326 20.4717 23.5602 20.3057Z"
      stroke={colorTokens['chubbs-outline']}
      strokeWidth="0.312838"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.5746 17.4094C15.213 17.3554 14.8435 17.4128 14.5153 17.574"
      stroke={colorTokens['chubbs-outline']}
      strokeWidth="0.312838"
      strokeMiterlimit="22.93"
      strokeLinecap="round"
    />
    <path
      d="M22.5393 17.4088C22.9009 17.3559 23.27 17.4133 23.5985 17.5734"
      stroke={colorTokens['chubbs-outline']}
      strokeWidth="0.312838"
      strokeMiterlimit="22.93"
      strokeLinecap="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30.1636 28.4302H14.5108C14.2196 28.4302 13.9404 28.5458 13.7346 28.7517C13.5287 28.9575 13.4131 29.2367 13.4131 29.5279C13.4131 29.819 13.5287 30.0982 13.7346 30.304C13.9404 30.5099 14.2196 30.6255 14.5108 30.6255H30.1526C30.4438 30.6255 30.723 30.5099 30.9288 30.304C31.1347 30.0982 31.2503 29.819 31.2503 29.5279C31.2503 29.2367 31.1347 28.9575 30.9288 28.7517C30.723 28.5458 30.4438 28.4302 30.1526 28.4302H30.1636Z"
      fill={colorTokens['chubbs-body']}
    />
    <path
      d="M30.1636 28.4302H14.5108C14.2196 28.4302 13.9404 28.5458 13.7346 28.7517C13.5287 28.9575 13.4131 29.2367 13.4131 29.5279C13.4131 29.819 13.5287 30.0982 13.7346 30.304C13.9404 30.5099 14.2196 30.6255 14.5108 30.6255H30.1526C30.4438 30.6255 30.723 30.5099 30.9288 30.304C31.1347 30.0982 31.2503 29.819 31.2503 29.5279C31.2503 29.2367 31.1347 28.9575 30.9288 28.7517C30.723 28.5458 30.4438 28.4302 30.1526 28.4302H30.1636Z"
      stroke={colorTokens['chubbs-outline']}
      strokeWidth="0.312838"
      strokeMiterlimit="22.93"
      strokeLinecap="round"
    />
    <path
      d="M30.3111 15.5039H6.25"
      stroke={colorTokens['chubbs-outline']}
      strokeWidth="0.312838"
      strokeMiterlimit="22.93"
      strokeLinecap="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.7739 15.4546L14.5105 16.437C17.5611 15.9439 20.6381 15.6305 23.7255 15.4985L14.7739 15.4546Z"
      fill={colorTokens['chubbs-outline']}
    />
    <path
      d="M14.7739 15.4546L14.5105 16.437C17.5611 15.9439 20.6381 15.6305 23.7255 15.4985L14.7739 15.4546Z"
      stroke={colorTokens['chubbs-outline']}
      strokeWidth="0.312838"
      strokeMiterlimit="22.93"
      strokeLinecap="round"
    />
    <path
      d="M14.8949 20.7146C14.8764 20.6946 14.8511 20.6822 14.8239 20.6799C14.7968 20.6775 14.7697 20.6853 14.748 20.7018C14.7268 20.7196 14.7136 20.745 14.7111 20.7726C14.7087 20.8001 14.7174 20.8275 14.7351 20.8487C14.7435 20.8599 14.7541 20.8691 14.7662 20.8759C14.7784 20.8827 14.7918 20.8868 14.8057 20.8881C14.8196 20.8893 14.8336 20.8875 14.8467 20.883C14.8599 20.8784 14.8719 20.8711 14.8821 20.8616C14.9032 20.8438 14.9165 20.8183 14.9189 20.7908C14.9213 20.7632 14.9127 20.7358 14.8949 20.7146Z"
      fill={colorTokens['chubbs-outline']}
      stroke={colorTokens['chubbs-outline']}
      strokeWidth="0.312838"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.3169 20.9149C22.3368 20.9341 22.3635 20.9446 22.3912 20.9441C22.4188 20.9436 22.4451 20.9321 22.4643 20.9122C22.4835 20.8923 22.494 20.8655 22.4935 20.8379C22.493 20.8102 22.4815 20.7839 22.4616 20.7647C22.4416 20.7455 22.4149 20.7351 22.3873 20.7356C22.3596 20.7361 22.3333 20.7476 22.3141 20.7675C22.2949 20.7874 22.2844 20.8141 22.285 20.8418C22.2855 20.8694 22.2969 20.8957 22.3169 20.9149Z"
      fill={colorTokens['chubbs-outline']}
      stroke={colorTokens['chubbs-outline']}
      strokeWidth="0.312838"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.803 24.7856H20.5802C20.5802 25.1539 20.4339 25.5071 20.1735 25.7675C19.913 26.0279 19.5599 26.1742 19.1916 26.1742C18.8233 26.1742 18.4701 26.0279 18.2097 25.7675C17.9493 25.5071 17.803 25.1539 17.803 24.7856Z"
      fill={colorTokens['chubbs-outline']}
      stroke={colorTokens['chubbs-outline']}
      strokeWidth="0.312838"
      strokeMiterlimit="22.93"
      strokeLinecap="round"
    />
  </svg>

);
