import React from 'react';
import { colorTokens } from '@/components/ui/colorTokens';

export const ChubbsHappy = () => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5 40.625C5.5 36.625 6.25 31.25 10 30H26.25C31.875 31.25 31.6667 38.5417 31.875 40.625H5Z"
      fill={colorTokens['chubbs-body']}
      stroke={colorTokens['chubbs-outline']}
      strokeWidth="0.3125"
    />
    <path
      d="M26.6524 25.699V16.3953C26.636 6.38019 10.8963 6.02446 10.9237 16.3953V26.498C10.9258 27.7005 10.8008 28.8019 10.5847 29.8403H26.9166C26.736 28.4689 26.6475 27.0853 26.6524 25.699Z"
      fill={colorTokens['chubbs-body']}
    />
    <path
      d="M26.7408 25.804V16.5003C26.7244 6.48517 10.9848 6.12944 11.0121 16.5003V26.603C11.0143 27.8577 10.8782 29.0023 10.6445 30.0801H27.0231C26.8304 28.6645 26.7358 27.2358 26.7408 25.804Z"
      stroke={colorTokens['chubbs-outline']}
      strokeWidth="0.311947"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.0407 21.9519C25.86 21.9519 25.6833 21.8983 25.533 21.7979C25.3827 21.6975 25.2655 21.5547 25.1964 21.3877C25.1272 21.2207 25.1091 21.037 25.1444 20.8597C25.1796 20.6824 25.2667 20.5195 25.3945 20.3917C25.5223 20.2639 25.6852 20.1769 25.8624 20.1416C26.0397 20.1063 26.2235 20.1244 26.3905 20.1936C26.5575 20.2628 26.7002 20.3799 26.8007 20.5302C26.9011 20.6805 26.9547 20.8572 26.9547 21.038C26.9547 21.2804 26.8584 21.5128 26.687 21.6842C26.5156 21.8556 26.2831 21.9519 26.0407 21.9519V21.9519Z"
      fill={colorTokens['chubbs-eyes']}
    />
    <path
      d="M26.0407 21.9519C25.86 21.9519 25.6833 21.8983 25.533 21.7979C25.3827 21.6975 25.2655 21.5547 25.1964 21.3877C25.1272 21.2207 25.1091 21.037 25.1444 20.8597C25.1796 20.6824 25.2667 20.5195 25.3945 20.3917C25.5223 20.2639 25.6852 20.1769 25.8624 20.1416C26.0397 20.1063 26.2235 20.1244 26.3905 20.1936C26.5575 20.2628 26.7002 20.3799 26.8007 20.5302C26.9011 20.6805 26.9547 20.8572 26.9547 21.038C26.9547 21.2804 26.8584 21.5128 26.687 21.6842C26.5156 21.8556 26.2831 21.9519 26.0407 21.9519V21.9519Z"
      stroke={colorTokens['chubbs-outline']}
      strokeWidth="0.311947"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.3682 20.124C18.5487 20.1251 18.7248 20.1796 18.8744 20.2807C19.024 20.3818 19.1403 20.5249 19.2086 20.692C19.277 20.8591 19.2943 21.0427 19.2584 21.2196C19.2225 21.3966 19.135 21.5589 19.007 21.6862C18.879 21.8134 18.7161 21.8999 18.539 21.9348C18.3618 21.9696 18.1784 21.9512 18.0117 21.8818C17.845 21.8125 17.7026 21.6954 17.6024 21.5452C17.5022 21.395 17.4487 21.2185 17.4487 21.038C17.4487 20.9175 17.4726 20.7982 17.5188 20.687C17.5651 20.5757 17.6329 20.4747 17.7184 20.3898C17.8038 20.3048 17.9052 20.2376 18.0167 20.192C18.1282 20.1464 18.2477 20.1233 18.3682 20.124Z"
      fill={colorTokens['chubbs-eyes']}
    />
    <path
      d="M18.3682 20.124C18.5487 20.1251 18.7248 20.1796 18.8744 20.2807C19.024 20.3818 19.1403 20.5249 19.2086 20.692C19.277 20.8591 19.2943 21.0427 19.2584 21.2196C19.2225 21.3966 19.135 21.5589 19.007 21.6862C18.879 21.8134 18.7161 21.8999 18.539 21.9348C18.3618 21.9696 18.1784 21.9512 18.0117 21.8818C17.845 21.8125 17.7026 21.6954 17.6024 21.5452C17.5022 21.395 17.4487 21.2185 17.4487 21.038C17.4487 20.9175 17.4726 20.7982 17.5188 20.687C17.5651 20.5757 17.6329 20.4747 17.7184 20.3898C17.8038 20.3048 17.9052 20.2376 18.0167 20.192C18.1282 20.1464 18.2477 20.1233 18.3682 20.124Z"
      stroke={colorTokens['chubbs-outline']}
      strokeWidth="0.311947"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M25.697 18.4274C26.0576 18.379 26.4244 18.436 26.7533 18.5916"
      stroke={colorTokens['chubbs-outline']}
      strokeWidth="0.311947"
      strokeMiterlimit="22.93"
      strokeLinecap="round"
    />
    <path
      d="M18.7516 18.1099C18.391 18.0571 18.0229 18.1144 17.6953 18.2741"
      stroke={colorTokens['chubbs-outline']}
      strokeWidth="0.311947"
      strokeMiterlimit="22.93"
      strokeLinecap="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.0058 22.0068H23.775C23.775 22.3741 23.6291 22.7262 23.3695 22.9859C23.1098 23.2456 22.7576 23.3914 22.3904 23.3914C22.0232 23.3914 21.671 23.2456 21.4113 22.9859C21.1517 22.7262 21.0058 22.3741 21.0058 22.0068Z"
      fill={colorTokens['chubbs-outline']}
    />
    <path
      d="M21.0058 22.0068H23.775C23.775 22.3741 23.6291 22.7262 23.3695 22.9859C23.1098 23.2456 22.7576 23.3914 22.3904 23.3914C22.0232 23.3914 21.671 23.2456 21.4113 22.9859C21.1517 22.7262 21.0058 22.3741 21.0058 22.0068Z"
      stroke={colorTokens['chubbs-outline']}
      strokeWidth="0.311947"
      strokeMiterlimit="22.93"
      strokeLinecap="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.4785 20.5617C26.4924 20.5609 26.5063 20.563 26.5192 20.568C26.5322 20.5729 26.544 20.5806 26.5538 20.5904C26.5636 20.6002 26.5713 20.612 26.5762 20.625C26.5812 20.6379 26.5833 20.6518 26.5825 20.6657C26.5825 20.6932 26.5716 20.7197 26.5521 20.7392C26.5326 20.7587 26.5061 20.7696 26.4785 20.7696C26.4514 20.7683 26.4257 20.7569 26.4065 20.7377C26.3873 20.7185 26.3759 20.6928 26.3745 20.6657C26.3745 20.6381 26.3855 20.6116 26.405 20.5921C26.4245 20.5726 26.4509 20.5617 26.4785 20.5617Z"
      fill={colorTokens['chubbs-outline']}
    />
    <path
      d="M26.4785 20.5617C26.4924 20.5609 26.5063 20.563 26.5192 20.568C26.5322 20.5729 26.544 20.5806 26.5538 20.5904C26.5636 20.6002 26.5713 20.612 26.5762 20.625C26.5812 20.6379 26.5833 20.6518 26.5825 20.6657C26.5825 20.6932 26.5716 20.7197 26.5521 20.7392C26.5326 20.7587 26.5061 20.7696 26.4785 20.7696C26.4514 20.7683 26.4257 20.7569 26.4065 20.7377C26.3873 20.7185 26.3759 20.6928 26.3745 20.6657C26.3745 20.6381 26.3855 20.6116 26.405 20.5921C26.4245 20.5726 26.4509 20.5617 26.4785 20.5617Z"
      stroke={colorTokens['chubbs-outline']}
      strokeWidth="0.311947"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.6908 20.7696C18.6632 20.7696 18.6368 20.7587 18.6173 20.7392C18.5978 20.7197 18.5868 20.6932 18.5868 20.6657C18.586 20.6518 18.5882 20.6379 18.5931 20.625C18.5981 20.612 18.6057 20.6002 18.6155 20.5904C18.6253 20.5806 18.6371 20.5729 18.6501 20.568C18.6631 20.563 18.6769 20.5609 18.6908 20.5617C18.7184 20.5617 18.7448 20.5726 18.7643 20.5921C18.7838 20.6116 18.7948 20.6381 18.7948 20.6657C18.7935 20.6928 18.7821 20.7185 18.7629 20.7377C18.7436 20.7569 18.718 20.7683 18.6908 20.7696V20.7696Z"
      fill={colorTokens['chubbs-outline']}
    />
    <path
      d="M18.6908 20.7696C18.6632 20.7696 18.6368 20.7587 18.6173 20.7392C18.5978 20.7197 18.5868 20.6932 18.5868 20.6657C18.586 20.6518 18.5882 20.6379 18.5931 20.625C18.5981 20.612 18.6057 20.6002 18.6155 20.5904C18.6253 20.5806 18.6371 20.5729 18.6501 20.568C18.6631 20.563 18.6769 20.5609 18.6908 20.5617C18.7184 20.5617 18.7448 20.5726 18.7643 20.5921C18.7838 20.6116 18.7948 20.6381 18.7948 20.6657C18.7935 20.6928 18.7821 20.7185 18.7629 20.7377C18.7436 20.7569 18.718 20.7683 18.6908 20.7696V20.7696Z"
      stroke={colorTokens['chubbs-outline']}
      strokeWidth="0.311947"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.0946 27.813H26.6919C26.9822 27.813 27.2606 27.9283 27.4659 28.1336C27.6711 28.3388 27.7865 28.6172 27.7865 28.9075C27.7865 29.1978 27.6711 29.4762 27.4659 29.6815C27.2606 29.8868 26.9822 30.0021 26.6919 30.0021H11.0946C10.8043 30.0021 10.5259 29.8868 10.3206 29.6815C10.1153 29.4762 10 29.1978 10 28.9075C10 28.6172 10.1153 28.3388 10.3206 28.1336C10.5259 27.9283 10.8043 27.813 11.0946 27.813Z"
      fill={colorTokens['chubbs-body']}
    />
    <path
      d="M11.0946 27.813H26.6919C26.9822 27.813 27.2606 27.9283 27.4659 28.1336C27.6711 28.3388 27.7865 28.6172 27.7865 28.9075C27.7865 29.1978 27.6711 29.4762 27.4659 29.6815C27.2606 29.8868 26.9822 30.0021 26.6919 30.0021H11.0946C10.8043 30.0021 10.5259 29.8868 10.3206 29.6815C10.1153 29.4762 10 29.1978 10 28.9075C10 28.6172 10.1153 28.3388 10.3206 28.1336C10.5259 27.9283 10.8043 27.813 11.0946 27.813Z"
      stroke={colorTokens['chubbs-outline']}
      strokeWidth="0.311947"
      strokeMiterlimit="22.93"
      strokeLinecap="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.758 29.9746L27.5 30.6252C24.4599 30.1333 20.8869 30.1389 17.8101 30.0074L26.758 29.9746Z"
      fill={colorTokens['chubbs-outline']}
    />
    <path
      d="M26.758 29.9746L27.5 30.6252C24.4599 30.1333 20.8869 30.1389 17.8101 30.0074L26.758 29.9746Z"
      stroke={colorTokens['chubbs-outline']}
      strokeWidth="0.311947"
      strokeMiterlimit="22.93"
      strokeLinecap="round"
    />
    <path
      d="M11.0073 16.6543H34.9998"
      stroke={colorTokens['chubbs-outline']}
      strokeWidth="0.311947"
      strokeMiterlimit="22.93"
      strokeLinecap="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.4785 16.6157L26.7193 17.5844C23.6774 17.0932 20.6091 16.7825 17.5305 16.654L26.4785 16.6157Z"
      fill={colorTokens['chubbs-outline']}
    />
    <path
      d="M26.4785 16.6157L26.7193 17.5844C23.6774 17.0932 20.6091 16.7825 17.5305 16.654L26.4785 16.6157Z"
      stroke={colorTokens['chubbs-outline']}
      strokeWidth="0.311947"
      strokeMiterlimit="22.93"
      strokeLinecap="round"
    />
  </svg>

);
